import * as React from 'react'
import Layout from '../components/layout'

const InformationHubPage = () => {
    return (
        <Layout title="Information hub">
            Information hub page
        </Layout>
    )
}

export default InformationHubPage
